import React from 'react'
import C1 from "../../images/game/cover.jpg"

const SingleCard = ({card,handleChoice,flipped,disabled}) => {
    const handleClick = () => {
        if(!disabled) {
            handleChoice(card)
        }
    }
  return (
    <div className="card-c" >
        <div className={flipped ? "flipped" : ""}>
            <img src={card.src} alt="card front" className='front cartoon'/>
            <img onClick={handleClick} 
            src={C1}
            alt="card back" 
            className='back cartoon'/>
        </div>
    </div>
  )
}

export default SingleCard