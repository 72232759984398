import { useState, useEffect } from 'react'
import Toggle from './components/Toggle/Toggle'
import Info from './components/Info/Info';
import Work from './components/Work/Work';
import Data from './database/Data'
import Game from './components/Game/Game';

const App = () => {
  const [loading, setLoading] = useState(false);
  const [toggled, setToggled] = useState(false);
  const handleClick = () => setToggled((prevBtn) => !prevBtn);
  const stylesMain = {backgroundColor: toggled ? "#223543" : "#f8fdff", color: toggled ? "#bad0de" : "#223543" }
  const stylesBG = {backgroundColor: toggled ? "#fdc765" : "#1969ff"}
  const styleLight = {color: toggled ? "#f8fdff" : "#161a26" }
  const styleGB = {color: toggled ? "#fdc765" : "#1969ff" }
  const styleIC = {backgroundColor: toggled ? "#161a26" : "#f8fdff",boxShadow: toggled ? "0px 4px 30px black" : "0px 0px 10px black" }

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])

  return (
  
    <div className='app-container' style={stylesMain}>
            {
        loading ?
        <div className='position'>
          <div class="boxes">
          <div class="box">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
          </div>
          <div class="box">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
          </div>
          <div class="box">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
          </div>
          <div class="box">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
          </div>
        </div>
      </div>
      :
        <div >
          <div className='colorbox'></div>
          <Toggle toggled={toggled} onClick={handleClick}/>
          <Info styleGB={styleGB} styleLight={styleLight} stylesBG={stylesBG} />
          <h1 className='portfolio' style={styleLight} >Projects<span style={styleGB}>.</span></h1>
          <Work styleIC={styleIC} details={Data} styleLight={styleLight}/>
          <Game styleGB={styleGB}/>
          <div className='colorbox'></div>
        </div>



      }
    </div>
  )
}

export default App