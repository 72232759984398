import React from 'react'
import './info.css'
import GMAIL from '../../images/design/gmail.png'
import LINKEDIN from '../../images/design/linkedin.png'
import INSTAGRAM from '../../images/design/instagram.png'
import YOUTUBE from '../../images/design/youtube.png'
import GITHUB from '../../images/design/github.png'



const Info = (props) => {
  return (
    <div className='info-container-bg'>
      <div className='info-container-bg'>

      <div className='block cText'>
        <h1><span style={props.styleLight}>Hi! My name is Verners</span><span style={props.styleGB}>.</span></h1>
        <p className='small-bio'>"I'm a <span style={props.styleGB}><b>Full Stack Developer</b></span> I joined an Accenture Bootcamp in 2021 and got an internship, kickstarting my journey in tech.
        I dedicated a year to learn AEM front-end development and Salesforce CMS development. Studying Computer Science, and in free time learning web development and other things.</p>
        <div>
          <p className='activities'>Working on:</p>
          <ul className='activities-list'>
            <li>Smart Contract, Blockchain <div className='div-active'><div className='active-dot'></div></div></li>
            <li>Trading bots <div className='div-active'><div className='active-dot'></div></div></li>
            <li>Content Management System Development <div className='div-active'><div className='non-active-dot'></div></div></li>
            <li>Web Development <div className='div-active'><div className='active-dot'></div></div></li>
            <li>Cyber security <div className='div-active'><div className='active-dot'></div></div></li>
          </ul>
        </div>
      </div>
      {/* YOUTUBE */}
      <div className='container-youtube'>
        <h2>Build a website from scratch with React!</h2>
        <iframe  src="https://www.youtube.com/embed/itbNDFwjJVs?si=hh9hLMgzUDo10eC_" title="YouTube video player" 
          frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen>
        </iframe>
      </div>

      <div className='block2 know'>
        {/* SKILLS */}
        <div className='skills'>
          <div className='webdev'>
            <p>
            Tools: <span style={props.styleGB}>HTML, CSS, JavaScript, PHP, SQL, XML, SASS, Less, Java, C++, C#, Python,
            React, MySQL, MongoDB, Bootstrap, Material UI, GIT, Django.</span>
            </p>
          </div>
          <div className='webdev'>
            <p>
              Adobe: <span style={props.styleGB}>Adobe Analytics, Adobe Target, Adobe Campaign, Adobe Experience Manager, Adobe Photoshop, Adobe Premiere Pro.</span>
            </p>
            <br />
          </div>
          <div className='webdev'>
            <p>
            Salesforce: <span style={props.styleGB}>APEX, SOQL, Administration</span>
            </p>
            <br />
          </div>
        </div>
        {/* CERTIFICATION */}
        <div className='block3 certification'>
          <h3>My Certifications</h3>
          <div className='line2' style={props.stylesBG}></div>
            <ul className='certification-ul' >
              <li>Salesforce Certified Associate</li>
              <li>Web Developer Zero-To-Mastery</li>
            </ul>
          </div>
        </div>
        {/* LINKS */}
        <div className='contacts'>
              <a href="mailto:grzybowski.work@gmail.com" target="_blank" rel="noreferrer"><img className='icon container-link-box' src={GMAIL} alt="gmail" /></a>
              <a href="https://www.linkedin.com/in/verners-grzibovskis/" target="_blank" rel="noreferrer"><img className='icon container-link-box' src={LINKEDIN} alt="linkedin" /></a>
              <a href="https://www.instagram.com/verners.dev" target="_blank" rel="noreferrer"><img className='icon container-link-box' src={INSTAGRAM} alt="instagram" /></a>
              <a href="https://www.youtube.com/watch?v=YoVU6-xlWfU&t=32s" target="_blank" rel="noreferrer"><img className='icon container-link-box' src={YOUTUBE} alt="youtube" /></a>
              <a href="https://github.com/grzibovskis" target="_blank" rel="noreferrer"><img className='icon container-link-box' src={GITHUB} alt="github"/></a>
            <br />
              <a className='button container-link-box' href="https://www.credly.com/users/verners-grzibovskis/badges" target="_blank" rel="noreferrer">Credly</a>
              <a className='button container-link-box' href="https://trailblazer.me/id/accenture-latvia-verners-grzibovskis" target="_blank" rel="noreferrer">Trailblazer</a>
        </div>
      </div>
    </div>
  )
}

export default Info