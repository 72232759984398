
const Data = [
    {
        id: 30,
        img: "p9.png",
        title: "Fortify",
        tools: "#react",
        link: "https://fortify-01.netlify.app/",
    },
    {
        id: 24,
        img: "p8.png",
        title: "01. Project",
        tools: "#react",
        link: "https://01projectport.netlify.app/",
    },
    {
        id: 24,
        img: "p7.png",
        title: "CrankyBicycle V2",
        tools: "#react",
        link: "https://crankybicycle.com/",
    },
    {
        id: 25,
        img: "p6.png",
        title: "CarMaster",
        tools: "#react",
        link: "https://carmasterr.netlify.app/",
    },
    {
        id: 26,
        img: "p5.png",
        title: "Top News",
        tools: "#react #api",
        link: "https://top-news-vg.netlify.app/",
    },
    {
        id: 27,
        img: "p4.png",
        title: "Weather App",
        tools: "#react #api",
        link: "https://turninternetoff.netlify.app/",
    },
    {
        id: 28,
        img: "p3.png",
        title: "Sir Shrek",
        tools: "#react",
        link: "https://sirshrek.netlify.app/",
    },
    {
        id: 29,
        img: "p2.png",
        title: "NetFlexo",
        tools: "#javaScript #html #css",
        link: "https://netflexo.netlify.app/",
    },
    {
        id: 30,
        img: "p1.png",
        title: "CrankyBicycle",
        tools: "#javaScript #html #css",
        link: "https://crankybicycle.netlify.app/",
    },
  ];

  export default Data;