import React, { useEffect, useState } from 'react'
import './game.css'
import C1 from"../../images/game/c1.jpg"
import C2 from"../../images/game/c2.jpg"
import C3 from"../../images/game/c3.jpg"
import C4 from"../../images/game/c4.jpg"
import C5 from"../../images/game/c5.jpg"
import C6 from"../../images/game/c6.jpg"
import C7 from"../../images/game/c7.jpg"
import C8 from"../../images/game/c8.jpg"
import C9 from"../../images/game/c9.jpg"
import C10 from"../../images/game/c10.jpg"
import C11 from"../../images/game/c11.jpg"
import C12 from"../../images/game/c12.jpg"

import SingleCard from './SingleCard';

const cardImages = [
    {src: C1, matched: false},
    {src: C2, matched: false},
    {src: C3, matched: false},
    {src: C4, matched: false},
    {src: C5, matched: false},
    {src: C6, matched: false},
    {src: C7, matched: false},
    {src: C8, matched: false},
    {src: C9, matched: false},
    {src: C10, matched: false},
    {src: C11, matched: false}, 
    {src: C12, matched: false} 
]

const Game = ({styleGB}) => {
    const [cards,setCards] = useState([]);
    const [turns, setTurns] = useState(0)
    const [choiceOne, setChoiceOne] = useState(null)
    const [choiceTwo, setChoiceTwo] = useState(null)
    const [disabled, setDisabled] = useState(false)

    const shuffleCards =() => {
        const shuffledCards = [...cardImages, ...cardImages]
        .sort(() => Math.random() - 0.5)
        .map((card) => ({ ...card,id: Math.random() }))

        setChoiceOne(null)
        setChoiceTwo(null)
        setCards(shuffledCards)
        setTurns(0)
    }
    const handleChoice = (card) => {
        choiceOne ? setChoiceTwo(card) : setChoiceOne(card)
    }

    useEffect(() => {
        if(choiceOne && choiceTwo) {
            setDisabled(true)

            if (choiceOne.src === choiceTwo.src) {
                setCards(prevCards => {
                    return prevCards.map(card => {
                        if (card.src === choiceOne.src){
                            return {...card, matched: true}
                        }else {
                            return card
                        }
                    })
                })
                resetTurn()
            } else {
                setTimeout(() => resetTurn(), 1000)
            }
        }
    }, [choiceOne, choiceTwo])
    const resetTurn = () => {
        setChoiceOne(null)
        setChoiceTwo(null)
        setTurns(prevTurns => prevTurns + 1 )
        setDisabled(false)
    }
    useEffect(() => {
        shuffleCards()
    }, [])

  return (
    <div className="game-g" id='game'>
        <p className='h1-g'>Hope you found everything you were searching for. <br/> Here is memory game for you, with little bit of nostalgia.</p>
        <button style={styleGB} className='button-g' onClick={shuffleCards}>New Game</button>

        <div className="card-grid">
            {cards.map(card => (
                <SingleCard 
                key={card.id} 
                card={card}
                handleChoice={handleChoice}
                flipped={card === choiceOne || card === choiceTwo || card.matched}
                disabled={disabled}
                />
                
            ))}
        </div>
        <p className='result' style={styleGB}>Turns: {turns}</p>
    </div>
  )
}

export default Game