import './work.css'
import LINK from '../../images/design/external-link.png'
import { Tilt } from 'react-tilt'

const Work = (props) => {

  return (
    <div className='work-container'>
        {props.details.map((value,index) => (

            <Tilt>
                <div className='card' >
                    <div>
                        <img className='photo' src={require(`../../images/${value.img}`)} alt="" />
                    </div>
                    <div className='desc'>
                        <h1>{value.title}</h1>
                        <p>{value.tools}</p>
                        <a href={value.link} target="_blank" rel="noreferrer" ><img className='icon' src={LINK} alt="link" /></a>
                    </div>
                </div>
            </Tilt>

        ))}
    </div>
  )
}

export default Work