import React from 'react'
import { FaMoon } from 'react-icons/fa';
import { BsSunFill } from 'react-icons/bs';
import './toggle.css';

const Toggle = ({toggled, onClick, styleGB}) => {
  return (
    <div className='toggle-container' id='info'>

      <div onClick={onClick} className={`toggle${toggled ? " act" : ""}`}>
        <BsSunFill className='night'/>
        <FaMoon className='day'/>
        <div className='notch'/>
      </div>
      
    </div>

  )
}

export default Toggle